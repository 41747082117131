<template>
  <v-dialog v-model="isModalOpen" width="650" persistent>
    <v-form ref="priceForm" v-model="is_valid" lazy-validation>
      <v-card>
        <v-toolbar class="py-2" flat>
          <v-toolbar-title>
            <h2 class="font-weight-regular text-h3">
              {{ $t("edit_batch") }}
            </h2>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon class="mr-0">
            <v-icon @click="close()">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="px-8">
          <span class="font-weight-light">{{ $t("lot_number") }} : </span>
          <span>{{ lotItem.lot_number }} </span>
          <v-row class="mt-9">
            <v-col class="d-flex px-4" cols="6" style="gap: 10px">
              <v-text-field
                :label="$t('selling_price_ht')"
                dense
                hide-details="auto"
                outlined
                type="number"
                v-model="lotItem.lot_price_ht"
                :rules="[
                  (v) =>
                    this.calculateMarge() > 0 ||
                    !v ||
                    $t('selling_price_condition'),
                ]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-simple-table class="my-5" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">{{ $t("selling_price_ht") }}</th>
                  <th class="text-left">{{ $t("tva") }}</th>
                  <th class="text-left">{{ $t("margin") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ lotItem.lot_price_ht }}</td>
                  <td>{{ lotItem.lot_tva }}</td>
                  <td>{{ calculateMarge() }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mb-6"
            color="#6100ea"
            @click="updatePrice(lotItem)"
            :disabled="!is_valid"
          >
            {{ this.$t("update_batch") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";

export default {
  mixins: [validationRules],
  props: {
    isModalOpen: Boolean,
    lotItem: Object,
    toggleModal: Function,
  },

  computed: {
    ...mapGetters({
      priceSettings: "settings/priceSettings",
    }),
    price_ht() {
      return (this.lotItem.price / (1 + this.lotItem.tva / 100)).toFixed(2);
    },
    purchase_price() {
      return (
        this.lotItem.purchase_price_ht *
        (1 + this.lotItem.purchase_tva / 100)
      ).toFixed(2);
    },

    margin() {
      if (!this.lotItem.price || !this.purchase_price) {
        return "-";
      }
      return Number(
        (100 * (this.lotItem.price - this.purchase_price)) / this.purchase_price
      ).toFixed(2);
    },

    VATs() {
      return this.priceSettings.vat_array.map((vat) => ({
        text: vat + "%",
        value: vat,
      }));
    },
  },
  data() {
    return {
      is_valid: false,
      message: null,
      qte_by_type_packaging: 0,

      lotItemsHeaders: [
        { text: this.$t("purchase_price_ht"), value: "purchase_price_ht" },
        { text: this.$t("purchase_tva"), value: "purchase_tva" },
        { text: this.$t("discount"), value: "discount" },
        { text: this.$t("price_ht"), value: "price_ht" },
        { text: this.$t("tva"), value: "tva" },
        { text: this.$t("margin"), value: "margin" },
        { text: this.$t("margin_objective"), value: "margin_objective" },
      ],
    };
  },
  watch: {},

  methods: {
    calculateMarge() {
      const purchase_price_ttc =
        this.lotItem.lot_purchase_price_ht *
        (1 + this.lotItem.lot_purchase_tva / 100);
      const price_ttc =
        this.lotItem.lot_price_ht * (1 + this.lotItem.lot_tva / 100);
      return Number(
        (100 * (price_ttc - purchase_price_ttc)) / purchase_price_ttc
      ).toFixed(2);
    },

    close() {
      this.toggleModal();
    },

    async updatePrice(lot) {
      try {
        if (this.$refs.priceForm.validate()) {
          this.toggleModal();

          await this.$store.dispatch("lots/updatePrice", {
            lot_id: lot.id,
            lot_price_ht: lot.lot_price_ht,
            lot_tva: lot.lot_tva,
          });

          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );
        }
      } catch (error) {
        console.log(error);
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>
<style scoped>
.v-input {
  font-size: 0.95em;
}

.v-input >>> input[type="number"] {
  -moz-appearance: textfield;
}
.v-input >>> input::-webkit-outer-spin-button,
.v-input >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.theme--light.v-label {
  color: rgba(0, 0, 0, 0.5);
}
</style>