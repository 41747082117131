<template>
  <div>
    <products-view-loader v-if="!loaded" />
    <v-row v-if="product.hubs && product.hubs.length > 0">
      <v-col cols="12" sm="12" md="12">
        <v-card elevation="0">
          <v-card-title class="text-left">
            <span>{{ $t("hubs") }}</span>
          </v-card-title>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left text-capitalize">
                      {{ $t("name") }}
                    </th>
                    <th class="text-left text-capitalize">
                      {{ $t("quantities") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in product.hubs" :key="item.id">
                    <td>{{ item.name }}</td>
                    <td>{{ item.qte_in_hub }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="product.variants && product.variants.length > 0">
      <v-col cols="12" sm="12" md="12">
        <v-card elevation="0">
          <v-card-title class="text-left">
            <span>{{ $t("variants") }}</span>
          </v-card-title>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t("name") }}
                    </th>
                    <th class="text-left">
                      {{ $t("sku") }}
                    </th>
                    <th class="text-left">
                      {{ $t("quantities") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in product.variants" :key="item.id">
                    <td>{{ item.name }}</td>
                    <td>{{ item.sku }}</td>
                    <td>{{ item.qte }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="loaded && (!product.hubs || product.hubs.length === 0)">
      <v-col cols="12" sm="12" md="12">
        <v-card elevation="0">
          <v-card-title class="text-left">
            <span>{{ $t("hubs") }}</span>
          </v-card-title>
          <v-card-text> No hubs found for this product </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  mounted() {
    console.log(this.product);
  },
  props: {
    loaded: {
      types: Boolean,
    },
    product: {
      types: Object,
    },
  },
};
</script>