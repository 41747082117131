<template>
  <div>
    <products-view-loader v-if="!loaded" />
    <v-container fluid v-else>
      <v-data-table
        :item-class="itemBG"
        :headers="headers"
        :items="lots"
        show-expand
        single-expand
        :loading="isLoadingData"
        :server-items-length="meta.totalItems"
        :options.sync="options"
        :items-per-page="30"
        :footer-props="{ 'items-per-page-options': [10, 30, 50] }"
      >
        <template v-slot:top>
          <v-row class="my-2">
            <v-col class="pl-0" cols="12" sm="3">
              <v-text-field
                outlined
                dense
                hide-details
                v-model="filters.search"
                :label="$t('search')"
                class=""
              ></v-text-field>
            </v-col>
            <v-col class="pl-0" cols="12" sm="2">
              <v-select
                dense
                clearable
                outlined
                :label="$t('status_*')"
                v-model="filters.status"
                :items="statuses"
                item-text="name"
                item-value="value"
              ></v-select>
            </v-col>
            <v-col class="pl-0" cols="12" sm="3">
              <v-autocomplete
                outlined
                dense
                hide-details
                clearable
                v-model="filters.hub_id"
                :items="product.hubs"
                item-text="name"
                item-value="id"
                :label="$t('hub')"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2" class="pl-0">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    clearable
                    v-model="filters.creation_date_range"
                    :label="$t('creation_date_range')"
                    append-icon="mdi-calendar"
                    readonly
                    chips
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.creation_date_range"
                  range
                  no-title
                  color="primary"
                  class="my-0 py-4"
                  header-color="primary"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="2" class="px-0">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    clearable
                    v-model="filters.expiration_date_range"
                    :label="$t('expiration_date_range')"
                    append-icon="mdi-calendar"
                    readonly
                    chips
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.expiration_date_range"
                  range
                  no-title
                  color="primary"
                  class="my-0 py-4"
                  header-color="primary"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-simple-table class="my-5" dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">{{ $t("init_quantity") }}</th>
                    <th class="text-left">{{ $t("purchase_tva") }}</th>
                    <th class="text-left">{{ $t("tva") }}</th>
                    <th class="text-left">{{ $t("selling_price_ht") }}</th>
                    <th class="text-left">{{ $t("status") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ item.qte_init_in_lot }}</td>
                    <td>{{ item.lot_purchase_tva }}</td>
                    <td>{{ item.lot_tva }}</td>
                    <td>{{ item.lot_price_ht }}</td>
                    <td>
                      <span
                        :class="getColor(item.status)"
                        class="text-capitalize"
                        >{{ item.status }}</span
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
        <template v-slot:no-data>
          {{ $t("no_data_available") }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            v-if="$admin.can('lot-update-price')"
            small
            class="mr-2"
            @click="editPrice(item)"
          >
            mdi-pencil
          </v-icon>
        </template>
        <template v-slot:item.lot_purchase_discount="{ item }">
          <span v-if="item?.lot_purchase_discount > 0">
            {{ item?.lot_purchase_discount + "%" }}
          </span>

          <span v-else>{{ "N/A" }}</span>
        </template>
      </v-data-table>
      <update-price-modal
        :isModalOpen="isUpdatePriceModalOpen"
        :toggleModal="toggleUpdatePrice"
        :lotItem="currentLot"
      ></update-price-modal>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import ProductsViewLoader from "../ProductsViewLoader";
import UpdatePriceModal from "../modals/UpdatePriceModal";
export default {
  computed: {
    ...mapGetters({
      lots: "lots/list",
      meta: "lots/meta",
      isLoadingData: "lots/isLoadingData",
      hubs: "hubs/activeHubs",
    }),

    filters: {
      get() {
        return this.$store.state.lots.filters;
      },
      set(value) {
        this.$store.commit("lots/SET_FILTERS", value);
      },
    },
  },

  watch: {
    options: {
      handler: debounce(async function (params) {
        if (this.isActive) {
          try {
            await this.$store.dispatch("lots/list", {
              ...params,
              ...this.filters,
            });
          } catch (error) {
            this.$store.dispatch(
              "alerts/error",
              error?.response?.data?.message
            );
          }
        }
      }, 1000),
      deep: true,
    },

    filters: {
      handler: debounce(async function (params) {
        if (this.isActive) {
          try {
            await this.$store.dispatch("lots/list", {
              ...params,
              ...this.options,
            });
          } catch (error) {
            this.$store.dispatch(
              "alerts/error",
              error?.response?.data?.message
            );
          }
        }
      }, 1000),
      deep: true,
    },

    search_hub: debounce(function (search) {
      if (this.is_loading_hubs) return;
      this.is_loading_hubs = true;

      this.$store
        .dispatch("hubs/list", {
          itemsPerPage: 30,
          for: "lots",
          search,
        })
        .then(() => {
          this.is_loading_hubs = false;
        });
    }, 1000),
  },

  components: {
    ProductsViewLoader,
    UpdatePriceModal,
  },

  created() {
    this.filters.product_id = this.$route.params.id;
  },

  props: {
    isActive: {
      types: Boolean,
    },
    product: {
      types: Object,
    },
  },

  data() {
    return {
      options: {},
      statuses: [
        { name: "Active", value: "active" },
        { name: "Archived", value: "archived" },
        { name: "Closed", value: "closed" },
      ],
      search_hub: null,

      is_loading_hubs: false,
      loaded: true,

      isUpdatePriceModalOpen: false,
      currentLot: {},
      headers: [
        {
          text: this.$t("lot_number"),
          align: "start",
          sortable: true,
          value: "lot_number",
        },
        {
          text: this.$t("hub"),
          align: "start",
          sortable: false,
          value: "hub.name",
        },

        {
          text: this.$t("quantity"),
          align: "start",
          sortable: true,
          value: "qte_in_lot",
        },

        {
          text: this.$t("reception_date"),
          value: "created_at",
          align: "end",
          sortable: true,
        },
        {
          text: this.$t("purchase_price_ht"),
          value: "lot_purchase_price_ht",
          align: "end",
          sortable: true,
        },

        {
          text: this.$t("expiration_date"),
          value: "expiration_date",
          align: "end",
          sortable: true,
        },
        {
          text: this.$t("discount"),
          value: "lot_purchase_discount",
          align: "end",
          sortable: true,
        },
        {
          text: this.$t("actions"),
          value: "actions",
          align: "center",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    getColor(status) {
      if (status === "active") return "font-weight-400 green--text";
      else return "font-weight-bold red--text";
    },
    itemBG(item) {
      if (!item.expiration_date) return "orange lighten-5";
      const expiration_date = new Date(item.expiration_date).getTime();
      const now = new Date().getTime();
      console.log(item?.expiration_date, expiration_date, now);
      if (expiration_date < now) return "red lighten-5";
      else return "";
    },

    toggleUpdatePrice() {
      this.isUpdatePriceModalOpen = !this.isUpdatePriceModalOpen;
    },
    editPrice(item) {
      item.lot_id = item.id;
      this.currentLot = item;
      this.toggleUpdatePrice();
    },
  },
};
</script>